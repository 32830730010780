import './csPage.css';
import icon_prev from '../assets/image/icon_prev.svg';
import logoKV from '../assets/image/logo_KV.svg';
import logoKV_gray from '../assets/image/logo_KV.svg';

const csPage = () => {
    return(
        <>
        <meta charSet="UTF-8" />
        <meta
            name="viewport"
            content="width=device-width,height=device-height,initial-scale=1,user-scalable=no"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        {/* SEO : S */}
        <meta name="description" content="" />
        <meta name="keywords" content="한국상품권협회" />
        <meta name="format-detection" content="telephone=no" />
        {/* SEO : E */}
        {/* SNS 공유 : S */}
        <meta property="og:url" data-role="url" content="한국상품권협회" />
        <meta property="og:title" data-role="title" content="한국상품권협회" />
        <meta property="og:image" data-role="image" content="/assets/image/sns.png" />
        <meta
            property="og:description"
            data-role="description"
            content="한국상품권협회"
        />
        {/* SNS 공유 : E */}
        <title>한국상품권협회</title>
        <link rel="shortcut icon" href="../assets/favicon/favicon.ico" />
        <link rel="icon" href="../assets/favicon/favicon.ico" />
        <link rel="stylesheet" type="text/css" href="../assets/css/jquery-ui.css" />
        <link rel="stylesheet" type="text/css" href="../assets/css/style.css" />
        <div className="wrapper" id="wrapper">
            <header className="header ">
            <div className="header__inner ">
                <div className="action__link">
                <a href="/">
                    <img src={icon_prev} alt="" />
                </a>
                </div>
                <h1>
                <i>
                    <img src={logoKV} alt="" />
                </i>
                고객센터
                </h1>
            </div>
            </header>
            <section className="content list" id="content">
            <div className="sub">
                <div className="cs__title">고객 서비스 안내</div>
                <div className="cs__list">
                <div className="cs__anchor bg__notice">
                    <a href="notice.html">
                    <strong>공지사항</strong>
                    <span>
                        한국상품권협회의
                        <br />
                        주요 공지를 확인하세요.
                    </span>
                    </a>
                </div>
                <div className="cs__anchor bg__shop">
                    <a href="shop.html">
                    <strong>쇼핑몰 안내</strong>
                    <span>
                        상품권을 사용할 수 있는
                        <br />
                        다양한 쇼핑몰을
                        <br />
                        이용해보세요.
                    </span>
                    </a>
                </div>
                <div className="cs__anchor bg__faq">
                    <a href="faq.html">
                    <strong>FAQ</strong>
                    <span>
                        자주 궁금해하시는
                        <br />
                        질문을 모았습니다.
                    </span>
                    </a>
                </div>
                <div className="cs__anchor bg__cs">
                    <strong>고객센터</strong>
                    <span>
                    평일, 주말
                    <br />
                    오전 9:30~오후 5:30
                    </span>
                    <a href="tel:1800-2434" className="call__link">
                    1800-2434
                    </a>
                </div>
                </div>
            </div>
            <div className="footer">
                <div className="footer__inner">
                <div className="footer__logo">
                    <img src={logoKV_gray} alt="한국상품권협회" />
                </div>
                <div className="footer__infomation">
                    서비스 제공 문의 (주)한국상품권협회
                    <br />
                    고객센터 : <a href="tel:1800-2434">1800-2434</a>
                </div>
                </div>
            </div>
            </section>
            
        </div>
        </>

    );
}

export default csPage;