import React, { useEffect } from 'react';

const AppDetection = ({ pinOdrNo }) => {
    console.log(pinOdrNo);
  useEffect(() => {
    const PinOrderNo = pinOdrNo;
    const userAgent = navigator.userAgent.toLowerCase();
    const appScheme = `naversearchapp://default?version=1`; // NAVER 확인 용
    const fallbackUrl = "https://play.google.com/store/apps/details?id=com.nhn.android.search"; // Google Play URL

    const iosAppStoreUrl = 'https://apps.apple.com/app/id<your-app-id>'; // <your-app-id>를 실제 앱 ID로 대체하세요
    console.log(userAgent);
    if (userAgent.match(/android/i)) { 
        alert("안드로이드 기기입니다.");
            
        setTimeout(() => {
        const intentUrl = "intent://default?version=1#Intent;scheme=naversearchapp;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.search;end";
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = intentUrl;
        document.body.appendChild(iframe);

        setTimeout(() => {
        document.body.removeChild(iframe);
        // 앱이 설치되지 않았을 경우 Google Play로 리디렉션
        alert("앱 미설치, 설치 페이지로 이동합니다.");
        window.location.href = fallbackUrl;
        }, 1500); // 적절한 대기 시간 설정
        }, 1000);


    } else {
        alert("안드로이드 기기가 아닙니다.");
    }

    // if (userAgent.match(/android/i)) {
    //   alert('Android 기기로 접속하셨습니다.');

    //   if (userAgent.match(/chrome/i)) {
    //     // 안드로이드의 크롬에서는 intent URL을 사용
    //     
    //     setTimeout(() => {
    //       const intentUrl = "intent://default?version=1#Intent;scheme=naversearchapp;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.search;end";
    //       const iframe = document.createElement('iframe');
    //       iframe.style.display = 'none';
    //       iframe.src = intentUrl;
    //       document.body.appendChild(iframe);

    //       setTimeout(() => {
    //         document.body.removeChild(iframe);
    //         // 앱이 설치되지 않았을 경우 Google Play로 리디렉션
    //         alert("앱 미설치, 설치 페이지로 이동합니다.");
    //         window.location.href = fallbackUrl;
    //       }, 1500); // 적절한 대기 시간 설정
    //     }, 1000);
    //   } else {
        // console.log("else");
    //     // 크롬이 아닌 경우
    //     const visitedAt = (new Date()).getTime();
    //     const iframe = document.createElement('iframe');
    //     iframe.style.visibility = 'hidden';
    //     iframe.src = appScheme; // 앱 호출을 위한 URL
    //     document.body.appendChild(iframe); // 앱으로 이동
    //     alert("앱 미설치");
    //     setTimeout(() => {
    //       document.body.removeChild(iframe); // back 호출 시 캐싱될 수 있으므로 제거
    //       window.location.href = fallbackUrl; // 앱이 설치되지 않았을 경우 Google Play로 이동
    //     }, 2000); // 충분한 대기 시간
    //   }
    // } else if (userAgent.match(/iphone|ipad|ipod/i)) {
    //   // iOS 기기 -> 웹 페이지로만 운영, App 없음.
    //   const visitedAt = (new Date()).getTime();
    //   alert('iOS 접속하셨습니다.');
    //   location.href = appScheme; // 앱으로 이동

    //   setTimeout(() => {
    //     if ((new Date()).getTime() - visitedAt < 2000) {
    //       alert('App 미설치. 설치 페이지로 이동합니다.');
    //       location.href = iosAppStoreUrl;
    //     }
    //   }, 500);
    // } else {
    //   // Android 및 iOS 외의 기기
    //   alert('이 웹 페이지는 Android 및 iOS 기기에서만 사용할 수 있습니다.');
    // }
  }, [pinOdrNo]);

  return (
    <div>
      {/* 페이지 내용을 여기에 추가할 수 있습니다 */}
      앱 설치 확인 페이지
    </div>
  );
};

export default AppDetection;
