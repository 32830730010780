import React, { useState } from 'react';
import logo_gray from '../assets/image/logo_gray.svg';

function Footer() {

    return (

    <div className="footer">
        <div className="footer__inner">
        <div className="footer__logo">
            <img src={logo_gray} alt="한국상품권협회" />
        </div>
        <div className="footer__infomation">
            서비스 제공 문의 (주)한국상품권협회<br />
            고객센터 : <a href="tel:1800-2434">1800-2434</a>
        </div>
        </div>
    </div>
    );


}

export default Footer;
