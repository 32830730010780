import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
const SearchPin = () => {

    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pinOdrNo = queryParams.get('pinOdrNo');
        
        console.log('pinOdrNo:', pinOdrNo);
    }, [location]);

    // const post_url = 'https://localhost:8443/search-pin';
    // axios.post( post_url, {
    //     "pinOdrNo":"202408200000000038",
    //     "autNo": "61634057d361cae0571d50b4c34b4769a9ab8003dba4463685ea72495e986c39", 
    //     "hpNo": "010-3021-9371", 
    //     "appId": "",
    //     "appOs": "",
    //     "appVer": ""
    // })
    // .then(function(response){
    //     console.log(response);
    // })
    // .then(function(error) {
    //     console.log(error);
    // });

    const response = {
        rspnCd: "0000",
        rspnMsg: "OK",
        pdctCdTotNum: 1,
        pdctPurcTotQty: 1,
        products: [
            {
                pdctCd: "C001",
                pdctNm: "롯데 모바일 상품권",
                pdctImgUrl: null,
                pinNo: [
                    "3802-2863-2909-5956",
                    "8410-0437-6951-3448",
                    "2700-8040-2101-6385"
                ]
            },
            {
                "pdctCd": "C002",
                "pdctNm": "신세계 상품권",
                "pdctImgUrl": null,
                "pinNo": [
                    "3087-5216-6822-8461"
                ]
            },
            {
                "pdctCd": "C003",
                "pdctNm": "온라인 도서문화 상품권",
                "pdctImgUrl": null,
                "pinNo": [
                    "8903-8956-8075-3508"
                ]
            }
        ]
    };
    console.log(response);

    const handleCopyToClipboard = (pins) => {
        // PIN 번호들을 줄바꿈으로 구분하여 하나의 문자열로 만듭니다.
        const pinText = pins.join('\n');

        // 클립보드에 복사합니다.
        navigator.clipboard.writeText(pinText).then(() => {
            alert('PIN 번호가 클립보드에 복사되었습니다.');
        }).catch(err => {
            console.error('클립보드 복사 실패:', err);
        });
    };

    return (
        <div>
            {response.products.map((product) => (
                <div key={product.pdctCd} style={{ marginBottom: '20px', border: '1px solid #ddd', padding: '10px' }}>
                    <h2>{product.pdctNm}</h2>
                    <div className="pin-list">
                        {product.pinNo.map((pin, index) => (
                            <div key={index} className="pin-item">{pin}</div>
                        ))}
                    </div>
                    <button onClick={() => handleCopyToClipboard(product.pinNo)}>
                        PIN 번호 복사
                    </button>
                </div>
            ))}
        </div>
    );
}

export default SearchPin;