import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './mainPage.css';
import logoKV from '../assets/image/logo_KV.svg';
import icon_prev from '../assets/image/icon_prev.svg';
import logo_gray from '../assets/image/logo_gray.svg';
import icon_i from '../assets/image/icon_i.svg';
import icon_guard from '../assets/image/icon_guard.svg';
import CryptoJS from 'crypto-js';
import axios from 'axios';


function MainPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [pinOdrNo, setPinOdrNo] = useState('');
  const [hpNo, setHpNo] = useState('');

  useEffect(() => {
    // 쿼리 파라미터에서 pinOdrNo를 추출합니다.
    const params = new URLSearchParams(location.search);
    // const pinOdrNoParam = params.get('pinOdrNo');
    // const hpNoParam = params.get('hpNo');
    const pinOdrNoParam = params.get('pinOrdNo');
    const hpNoParam = params.get('phoneNo');
    if (pinOdrNoParam) {
      setPinOdrNo(pinOdrNoParam);
    }
    if (hpNoParam) {
      setHpNo(hpNoParam);
    }

  }, [location.search]);
  


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = () => {
    // SHA-256 암호화
    const hashedValue = CryptoJS.SHA256(inputValue).toString(CryptoJS.enc.Hex).toLowerCase();

    var postObject = {
      pinOdrNo: pinOdrNo,
      autNo: hashedValue,
      hpNo: hpNo,
      appId: "testAppId",
      appOs: "iOS",
      appVer: "0.0.1"
    };

    // 123456 SHA-256 암호화 값.
    if (hashedValue === '8d969eef6ecad3c29a3a629280e686cf0c3f5d5a86aff3ca12020c923adc6c92') {
      navigate('/ListPageSample');
      // navigate('/ListPage');
    } else {
      alert("인증번호가 틀렸습니다. 다시 시도해주세요.");
    }

    // pinhub와 통신 https://puapi.4usoft.kr/
    // http://localhost:8080/search-pin
    /*
    const response = axios.post('http://k-pin.co.kr/api/search-pin', postObject).then((response) => {


      console.log("Response: ", response);
      if ( response.data.rspnCd === '0000' && response.data.rspnMsg === 'OK') {
        
        navigate('/ListPage', {
          state: {
            pinOdrNo: pinOdrNo,
            hpNo: hpNo,
            products: response.data.products,
          },
        });
  
      } else {
        alert('인증번호가 올바르지 않습니다.');
      }
    });
    */
    


  };

  const handleAlertClick = (event) => {
    event.preventDefault(); // 기본 링크 동작을 막습니다

    const userConfirmed = window.confirm('인증번호를 재발급 받으시겠습니까?'); 

    if (userConfirmed) {
      window.alert('인증번호가 문자로 발송되었습니다.');
        var postObjectAuth = {
          pinOdrNo: pinOdrNo,
          hpNo: hpNo,
          appId: "testAppId",
          appOs: "iOS",
          appVer: "0.0.1"
      }
      
      // pinhub와 통신 https://puapi.4usoft.kr/
      // http://localhost:8080/search-pin
      /*
      const response = axios.post('http://k-pin.co.kr/api/reset-auth-number', postObjectAuth).then((response) => {
        console.log('Response:', response);

        if (response.data.rspnCd === '0000' && response.data.rspnMsg === 'OK') {
          alert("인증번호가 전송되었습니다.");
        } else {
          alert("인증번호 전송이 실패되었습니다.");
        }
      });
      */

      
    }



  };



  return (
    <div className="wrapper" id="wrapper">
      <header className="header">
      <div className="header__inner">
        {/* 메인페이지 뒤로가기 버튼 비활성화 */}
        {/* <div className="action__link">
          <a href="/">
            <img src={icon_prev} alt="Previous" />
          </a>
        </div> */}
        <h1>
          <img src={logoKV} alt="Logo KV" />
          상품권 확인
        </h1>
      </div>
    </header>

      <section className="content" id="content">
        <div className="check__box">
          <div className="check__mark">
            <img src={icon_guard} alt="" />
          </div>
          <div className="check__code">
            <strong>악성 코드 없음.</strong>
            <span>악성코드 검사 결과 : 정상</span>
          </div>
        </div>

        <div className="page__auth">
          <div className="check__title">휴대폰번호</div>
          <div className="check__mobile">
            <input type="text" className="text" maxLength="3" placeholder="010" />
            <span className="hyphen">-</span>
            <input type="text" className="text" maxLength="4" placeholder="****" />
            <span className="hyphen">-</span>
            <input type="text" className="text" maxLength="4" placeholder="****" />
          </div>

          <div className="check__title">인증번호</div>
          <div className="check__cetify">
            <input type="text" className="text" maxLength="10" placeholder="인증번호를 입력해 주세요" value={inputValue} onChange={handleInputChange} />
            <div>
            <a href="#none" onClick={handleAlertClick}>
              <img src={icon_i} alt="info" />인증번호를 잊어버렸어요
            </a>
            </div>
          </div>

          <div className="btn__group">
          <button onClick={handleButtonClick} className="btn btn__full">
          상품권 확인
          </button>
          </div>
          
        </div>
        
      </section>
      
    </div>
    
    
  );
}

export default MainPage;
