import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './mainPage.css';
import logoKV from '../assets/image/logo_KV.svg';
import icon_prev from '../assets/image/icon_prev.svg';
import logo_gray from '../assets/image/logo_gray.svg';
import icon_i from '../assets/image/icon_i.svg';
import icon_guard from '../assets/image/icon_guard.svg';
import CryptoJS from 'crypto-js';
import axios from 'axios';


function MainPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [pinOdrNo, setPinOdrNo] = useState('');
  const [hpNo, setHpNo] = useState('');
  const [midPhoneNum, setMidPhoneNum] = useState('');
  const [lastPhoneNum, setLastPhoneNum] = useState('');


  // http://localhost:3000/MainPageDev?pinOrdNo=202410020000000099&phoneNo=01030219371
  // http://localhost:3000/MainPageDev?pinOrdNo=202410020000000100&phoneNo=01030219371 -> [893699]
  // [test 용] 주문번호, 핸드폰번호, 인증번호

  /*
  {
    "pinOdrNo": "202410020000000099",
    "autNo": "051B315EE1B005FC366271CEC99CC859DEDF2015189B579317E090C84AE23580",
    "hpNo": "01030219371",
    "appId": "testAppId",
    "appOs": "iOS",
    "appVer": "0.0.1"
  }
  */
  useEffect(() => {

    const params = new URLSearchParams(location.search);
    // const pinOdrNoParam = params.get('pinOdrNo');
    // const hpNoParam = params.get('hpNo');
    const pinOdrNoParam = params.get('pinOrdNo');
    const hpNoParam = params.get('phoneNo');
    if (pinOdrNoParam) {
      setPinOdrNo(pinOdrNoParam);
    } else {
      // 주문번호 값 안넘어올 시 (에러메세지 + 앱 강제종료)
      alert('잘못된 접근입니다.\n올바르지 않은 주문번호입니다.');
      // 앱 종료 logic

    }

    if (hpNoParam) {
      setHpNo(hpNoParam);
      setMidPhoneNum(hpNoParam.substring(3, 7)); 
      setLastPhoneNum(hpNoParam.substring(7)); 
    } else {
      // 휴대폰번호 값 안넘어올 시 (에러메세지 + 앱 강제종료)
      alert('잘못된 접근입니다.\n올바르지 않은 휴대폰 번호입니다.');
      // 앱 종료 logic

    }

  }, [location.search]);



  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = () => {
    // SHA-256 암호화
    const hashedValue = CryptoJS.SHA256(inputValue).toString(CryptoJS.enc.Hex).toLowerCase();

    var postObject = {
      pinOdrNo: pinOdrNo,
      autNo: hashedValue,
      hpNo: hpNo,
      appId: "testAppId",
      appOs: "iOS",
      appVer: "0.0.1"
    };

    axios.post('https://api.k-pin.co.kr/api/search-pin', postObject)
      .then((response) => {
        console.log("response", response);

        if ( response.data.rspnCd === "0000" && response.data.rspnMsg === "OK") {

          navigate('/ListPageSampleDev', {
            state: {
              pinOdrNo: pinOdrNo,
              hpNo: hpNo,
              products: response.data.products,
            }

          });
        } else {

          alert('인증번호가 올바르지 않습니다.');

        };
      })
      .catch((error) => {
        console.error("Error occurred:", error);
      });


    /*********************************************************************************************************/
    


  };

  const handleAlertClick = (event) => {
    event.preventDefault(); // 기본 링크 동작을 막습니다

    const userConfirmed = window.confirm('인증번호를 재발급 받으시겠습니까?'); 

    if (userConfirmed) {
      window.alert('인증번호가 문자로 발송되었습니다.');
        var postObjectAuth = {
          pinOdrNo: pinOdrNo,
          hpNo: hpNo,
          appId: "testAppId",
          appOs: "iOS",
          appVer: "0.0.1"
      }
      
      
      const response = axios.post('https://api.k-pin.co.kr/api/reset-auth-number', postObjectAuth).then((response) => {
        if (response.data.rspnCd === '0000' && response.data.rspnMsg === 'OK') {
          alert("인증번호가 전송되었습니다.");
        } else {
          alert("인증번호 전송이 실패되었습니다.");
        }
      });
      
    }

  };



  return (
    <div className="wrapper" id="wrapper">
      <header className="header">
      <div className="header__inner">
        {/* 메인페이지 뒤로가기 버튼 비활성화 */}
        {/* <div className="action__link">
          <a href="/">
            <img src={icon_prev} alt="Previous" />
          </a>
        </div> */}
        <h1>
          <img src={logoKV} alt="Logo KV" />
          상품권 확인
        </h1>
      </div>
    </header>

      <section className="content" id="content">
        <div className="check__box">
          <div className="check__mark">
            <img src={icon_guard} alt="" />
          </div>
          <div className="check__code">
            <strong>악성 코드 없음.</strong>
            <span>악성코드 검사 결과 : 정상</span>
          </div>
        </div>

        <div className="page__auth">
          <div className="check__title">휴대폰번호</div>
          <div className="check__mobile">
            <input type="text" className="text" maxLength="3" placeholder="010" />
            <span className="hyphen">-</span>
            <input type="text" className="text" maxLength="4" placeholder="****" value={midPhoneNum} onChange={(e) => setMidPhoneNum(e.target.value)} />
            <span className="hyphen">-</span>
            <input type="text" className="text" maxLength="4" placeholder="****" value={lastPhoneNum} onChange={(e) => setLastPhoneNum(e.target.value)} />
          </div>

          <div className="check__title">인증번호</div>
          <div className="check__cetify">
            <input 
              type="text" 
              className="text" 
              maxLength="10" 
              placeholder="인증번호를 입력해 주세요" 
              value={inputValue} 
              onChange={handleInputChange} 
              onFocus={(e) => e.target.placeholder = ""} 
              style={{ textAlign: 'center' }} 
            />
            <div>
            <a href="#none" onClick={handleAlertClick}>
              <img src={icon_i} alt="info" />인증번호를 잊어버렸어요
            </a>
            </div>
          </div>

          <div className="btn__group">
          <button onClick={handleButtonClick} className="btn btn__full">
          상품권 확인
          </button>
          </div>
          
        </div>
        
      </section>
      
    </div>
    
    
  );
}

export default MainPage;