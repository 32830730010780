import React, {useEffect} from 'react';
// 필요한 경우 CSS 파일을 import 합니다.
// import './App.css';
import './listPageSample.css';
import icon_prev from '../assets/image/icon_prev.svg';
import logoKV from '../assets/image/logo_KV.svg';
import icon_copy from '../assets/image/icon_copy.svg';
import img_cultureland from '../assets/image/img_cultureland.png';
import img_googleplay from '../assets/image/img_googleplay.png';
import icon_plus from "../assets/image/icon_plus.svg";
import img_book from "../assets/image/img_book.png";
import img_munsang from "../assets/image/img_munsang.png";
import img_nexon from "../assets/image/img_nexon.png";
import img_afreecatv from "../assets/image/img_afreecatv.png";
import img_teencash from "../assets/image/img_teencash.png";
import img_eggmoney from "../assets/image/img_eggmoney.png";
import img_shinsegae from "../assets/image/img_shinsegae.png";
import { useLocation, useNavigate } from 'react-router-dom';





function SearchPin() {

  const productImageMap = {
    "롯데 모바일 상품권": "",
    "신세계 상품권": img_shinsegae,
    "온라인 도서문화 상품권": img_book,
    "나누리 상품권": "",
    "XBOX 상품권": "",
    "OFFICE 상품권": "",
    "넥슨카드 상품권": img_nexon,
    "온라인 문화상품권": img_munsang,
    "해피머니 상품권": "",
    "틴캐시 상품권": img_teencash,
    "에그머니 상품권": img_eggmoney,
    "CU 모바일 상품권": "",
    "아프리카 별풍선 상품권": img_afreecatv,
    "퍼니카드 상품권": "",
    "구글플레이 기프트코드 상품권": img_googleplay,
    "모바일 교환권": "",
  };
  
  const location = useLocation();
  const navigate = useNavigate();

  const { pinOdrNo, hpNo, products } = location.state || {};
  
  if ( pinOdrNo == null || hpNo == null || products == null) {
    console.log("비정상적인 접근입니다.");
  }
  if(!products || products.length === 0) {
    alert("비정상적인 접근입니다.");
    return (
      <div>
        <a href="https://k-pin.co.kr/">
          <button>홈</button>
        </a>
      </div>
    ); 
  }

  const copyToClipboard = (pins) => {
    const pinText = pins.join('\n'); 
    navigator.clipboard.writeText(pinText).then(
      () => {
        alert('클립보드에 복사되었습니다.');
      },
      (err) => {
        alert('클립보드 복사에 실패했습니다.');
      }
    );
  };

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <div className="wrapper" id="wrapper">
      <header className="header">
        <div className="header__inner">
          <div className="action__link">
            <a onClick={handleBack}>
              <img src={icon_prev} alt="Back" />
            </a>
          </div>
          <h1>
            <i>
              <img src={logoKV} alt="Logo" />
            </i>
            상품권 목록
          </h1>
        </div>
      </header>

      <section className="content list" id="content">
        <div className="sub">

        {/* 임시 데이터 */}
        {/* <div className="gift__box">
            <div className="gift__bg">
              <div className="gift__won">
                <div className="voucher__box">
                  <figure><img src={img_googleplay} alt="Google Play" /></figure>
                  <span>50,000원</span>
                </div>
                <strong className="voucher__subject">구글플레이 기프트카드</strong>
                <span className="voucher__won">50,000원</span>
              </div>
              <div className="gift__number">
                <ul>
                  <li>1234-5678-9012-3456-7890</li>
                  <li>9876-5432-1098-7654-3210</li>
                  <li>1234-5678-9012-3456-7890</li>
                </ul>
              </div>
              <a href="#none" className="btn btn__copy">
                <img src={icon_copy} alt="Copy" />상품권 PIN번호 전체복사
              </a>
            </div>
        </div> */}



        {products.length > 0 ? (
            products.map((product, index) => (
              <div className="gift__box" key={index}>
                <div className="gift__bg">
                  <div className="gift__won">
                    <div className="voucher__box">
                      <figure>
                        <img
                          src={productImageMap[product.pdctNm] || logoKV} 
                          alt={product.pdctNm}
                        />
                      </figure>
                    </div>
                    <strong className="voucher__subject">{product.pdctNm}</strong>
                    <span className="voucher__won">50,000원</span> 
                  </div>
                  <div className="gift__number">
                    <ul>
                      {product.pinNo.map((pin, pinIndex) => (
                        <li key={pinIndex}>{pin}</li>
                      ))}
                    </ul>
                  </div>
                  <button
                    className="btn btn__copy"
                    onClick={() => copyToClipboard(product.pinNo)}
                  >
                    <img src={icon_copy} alt="Copy" />
                    상품권 PIN번호 전체복사
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>상품권 목록이 없습니다.</p>
          )}

      {/* 

          

          <div className="gift__box">
            <div className="gift__bg">
              <div className="gift__won">
                <div className="voucher__box">
                  <figure><img src={img_googleplay} alt="Google Play" /></figure>
                  <span>50,000원</span>
                </div>
                <strong className="voucher__subject">구글플레이 기프트카드</strong>
                <span className="voucher__won">50,000원</span>
              </div>
              <div className="gift__number">
                <ul>
                  <li>1234-5678-9012-3456-7890</li>
                  <li>9876-5432-1098-7654-3210</li>
                  <li>1234-5678-9012-3456-7890</li>
                </ul>
              </div>
              <a href="#none" className="btn btn__copy">
                <img src={icon_copy} alt="Copy" />상품권 PIN번호 전체복사
              </a>
            </div>
          </div>


          <div className="gift__box">
            <div className="gift__bg">
              <div className="gift__won">
                <div className="voucher__box">
                  <figure><img src={img_cultureland} alt="Culture Land" /></figure>
                  <span>50,000원</span>
                </div>
                <strong className="voucher__subject">컬쳐랜드 상품권</strong>
                <span className="voucher__won">50,000원</span>
              </div>
              <div className="gift__number">
                <ul>
                  <li>1234-5678-9012-3456-7890</li>
                  <li>9876-5432-1098-7654-3210</li>
                  <li>1234-5678-9012-3456-7890</li>
                </ul>
              </div>
              <a href="#none" className="btn btn__copy">
                <img src={icon_copy} alt="Copy" />상품권 PIN번호 전체복사
              </a>
            </div>
          </div>

          <div className="gift__box">
            <div className="gift__bg">
              <div className="gift__won">
                <div className="voucher__box">
                  <figure><img src={img_munsang} alt="Culture Land" /></figure>
                  <span>50,000원</span>
                </div>
                <strong className="voucher__subject">온라인 문화상품권</strong>
                <span className="voucher__won">50,000원</span>
              </div>
              <div className="gift__number">
                <ul>
                  <li>1234-5678-9012-3456-7890</li>
                  <li>9876-5432-1098-7654-3210</li>
                  <li>1234-5678-9012-3456-7890</li>
                </ul>
              </div>
              <a href="#none" className="btn btn__copy">
                <img src={icon_copy} alt="Copy" />상품권 PIN번호 전체복사
              </a>
            </div>
          </div>

          <div className="gift__box">
            <div className="gift__bg">
              <div className="gift__won">
                <div className="voucher__box">
                  <figure><img src={img_book} alt="Culture Land" /></figure>
                  <span>50,000원</span>
                </div>
                <strong className="voucher__subject">온라인 도서문화 상품권</strong>
                <span className="voucher__won">50,000원</span>
              </div>
              <div className="gift__number">
                <ul>
                  <li>1234-5678-9012-3456-7890</li>
                  <li>9876-5432-1098-7654-3210</li>
                  <li>1234-5678-9012-3456-7890</li>
                </ul>
              </div>
              <a href="#none" className="btn btn__copy">
                <img src={icon_copy} alt="Copy" />상품권 PIN번호 전체복사
              </a>
            </div>
          </div>

          <div className="gift__box">
            <div className="gift__bg">
              <div className="gift__won">
                <div className="voucher__box">
                  <figure><img src={img_nexon} alt="Culture Land" /></figure>
                  <span>50,000원</span>
                </div>
                <strong className="voucher__subject">넥슨카드 상품권</strong>
                <span className="voucher__won">50,000원</span>
              </div>
              <div className="gift__number">
                <ul>
                  <li>1234-5678-9012-3456-7890</li>
                  <li>9876-5432-1098-7654-3210</li>
                  <li>1234-5678-9012-3456-7890</li>
                </ul>
              </div>
              <a href="#none" className="btn btn__copy">
                <img src={icon_copy} alt="Copy" />상품권 PIN번호 전체복사
              </a>
            </div>
          </div>

          <div className="gift__box">
            <div className="gift__bg">
              <div className="gift__won">
                <div className="voucher__box">
                  <figure><img src={img_afreecatv} alt="Culture Land" /></figure>
                  <span>50,000원</span>
                </div>
                <strong className="voucher__subject">아프리카 별풍선 상품권</strong>
                <span className="voucher__won">50,000원</span>
              </div>
              <div className="gift__number">
                <ul>
                  <li>1234-5678-9012-3456-7890</li>
                  <li>9876-5432-1098-7654-3210</li>
                  <li>1234-5678-9012-3456-7890</li>
                </ul>
              </div>
              <a href="#none" className="btn btn__copy">
                <img src={icon_copy} alt="Copy" />상품권 PIN번호 전체복사
              </a>
            </div>
          </div>

          <div className="gift__box">
            <div className="gift__bg">
              <div className="gift__won">
                <div className="voucher__box">
                  <figure><img src={img_teencash} alt="Culture Land" /></figure>
                  <span>50,000원</span>
                </div>
                <strong className="voucher__subject">틴캐시 상품권</strong>
                <span className="voucher__won">50,000원</span>
              </div>
              <div className="gift__number">
                <ul>
                  <li>1234-5678-9012-3456-7890</li>
                  <li>9876-5432-1098-7654-3210</li>
                  <li>1234-5678-9012-3456-7890</li>
                </ul>
              </div>
              <a href="#none" className="btn btn__copy">
                <img src={icon_copy} alt="Copy" />상품권 PIN번호 전체복사
              </a>
            </div>
          </div>

          <div className="gift__box">
            <div className="gift__bg">
              <div className="gift__won">
                <div className="voucher__box">
                  <figure><img src={img_eggmoney} alt="Culture Land" /></figure>
                  <span>50,000원</span>
                </div>
                <strong className="voucher__subject">에그머니 상품권</strong>
                <span className="voucher__won">50,000원</span>
              </div>
              <div className="gift__number">
                <ul>
                  <li>1234-5678-9012-3456-7890</li>
                  <li>9876-5432-1098-7654-3210</li>
                  <li>1234-5678-9012-3456-7890</li>
                </ul>
              </div>
              <a href="#none" className="btn btn__copy">
                <img src={icon_copy} alt="Copy" />상품권 PIN번호 전체복사
              </a>
            </div>
          </div>

          <div className="gift__box">
            <div className="gift__bg">
              <div className="gift__won">
                <div className="voucher__box">
                  <figure><img src={img_shinsegae} alt="Culture Land" /></figure>
                  <span>50,000원</span>
                </div>
                <strong className="voucher__subject">신세계 상품권</strong>
                <span className="voucher__won">50,000원</span>
              </div>
              <div className="gift__number">
                <ul>
                  <li>1234-5678-9012-3456-7890</li>
                  <li>9876-5432-1098-7654-3210</li>
                  <li>1234-5678-9012-3456-7890</li>
                </ul>
              </div>
              <a href="#none" className="btn btn__copy">
                <img src={icon_copy} alt="Copy" />상품권 PIN번호 전체복사
              </a>
            </div>
          </div> */}





          {/* 더 많은 gift__box 항목을 여기에 추가합니다 */}

          {/* <div className="btn__group">
            <a href="#none" className="btn btn__full">
              <img src={icon_plus} alt="More" />더 보기
            </a>
          </div> 
        */}
        </div>
      </section>
    </div>
  );
}

export default SearchPin;
